import PropTypes from 'prop-types';
import {
  NEXT_STEP,
  PREV_STEP,
  FINISH_LEARNING,
  STAGE_LEARNING,
  STAGE_VALIDATION,
  useAppState,
  useAppStateDispatch,
} from '../../contexts/AppStateProvider';

const Step3 = () => {
  const dispatch = useAppStateDispatch();
  const appState = useAppState();

  const { stage, data, progress } = appState;
  const {
    newRecipientToggle,
    newRecipientName,
    newRecipientAccount,
    existingRecipientAccount,
    amount,
    sourceAccount,
  } = data.formData;

  const cancel = () => dispatch({ type: PREV_STEP });
  const submit = () => {
    switch (stage) {
      case STAGE_LEARNING:
        if (progress >= 100) {
          dispatch({ type: FINISH_LEARNING });
        }
        break;
      case STAGE_VALIDATION:
        dispatch({ type: NEXT_STEP });
        break;
      default:
    }
  };

  return (
    <>
      <div className="card w-2/4 bg-base-100 shadow-xl mb-16
        bg-white dark:bg-gray-900 text-black dark:text-white">
        <div className="card-body">
          <h2 className="card-title">Confirm Transaction Details</h2>
          <div className="overflow-x-auto">
            <table className="table">
              <tbody>
                <tr>
                  <td>From</td>
                  <td className="text-right">{sourceAccount}</td>
                </tr>
                <tr>
                  <td>To</td>
                  <td className="text-right">
                    {newRecipientToggle
                      ? `${newRecipientName} - ${newRecipientAccount}`
                      : existingRecipientAccount}
                  </td>
                </tr>
                <tr>
                  <td>Amount</td>
                  <td className="text-right">{amount}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="card-actions justify-end">
            <button
              className="btn btn-primary"
              onClick={submit}
              disabled={progress < 100}
            >
              Confirm
            </button>
            <button className="btn" onClick={cancel}>Cancel</button>
          </div>
        </div>
      </div>
    </>
  );
};

Step3.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      amount: PropTypes.string,
      sourceAccount: PropTypes.string,
    }),
  }),
};

export default Step3;
