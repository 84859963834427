import trap from 'ci-trap-web';
import EventEmitter from 'events';
import platform from 'platform';

const MOUSE_MOVE_EVENT_TYPE = 0;

class Sentinel extends EventEmitter {
  constructor() {
    super();

    // Set up state
    this.reset(true, false);

    // Initialize Trap + Sentinel
    this.initialize(document, {
      /* eslint-disable no-underscore-dangle */
      apiKeyName: 'GRABOXY-API-KEY',
      apiKeyValue: window.__RUNTIME_CONFIG__.GRABOXY_API_KEY,
      trapUrl: window.__RUNTIME_CONFIG__.GRABOXY_TRAP_URL,
      sentinelInterval: window.__RUNTIME_CONFIG__.GRABOXY_SENTINEL_INTERVAL,
      sentinelUrl: window.__RUNTIME_CONFIG__.GRABOXY_SENTINEL_URL,
      trapBufferSizeLimit:
        window.__RUNTIME_CONFIG__.GRABOXY_TRAP_BUFFER_SIZE_LIMIT,
      trapBufferTimeout:
        window.__RUNTIME_CONFIG__.GRABOXY_TRAP_BUFFER_TIMEOUT,
      submitDataToTrap: window
        .__RUNTIME_CONFIG__
        .SUBMIT_DATA_TO_TRAP
        .toLowerCase() === 'true',
      requiredEventCount: parseInt(
        window.__RUNTIME_CONFIG__.REQUIRED_EVENT_COUNT,
        10,
      ),
      /* eslint-enable no-underscore-dangle */
    });
  }

  // eslint-disable-next-line class-methods-use-this
  getPlatformInfo() {
    const os = platform.os.family;
    const browser = platform.name;
    return { os, browser };
  }

  initialize(document, config) {
    this._config = {
      ...config,
      ...this._config,
    };
    trap.mount(document);
    trap.apiKeyName(this.config.apiKeyName);
    trap.apiKeyValue(this.config.apiKeyValue);

    trap.setTransportMethod(this.config.submitDataToTrap ? 'http' : 'none');
    trap.setCollectEvents(true);

    trap.url(this.config.trapUrl);
    trap.bufferSizeLimit(this.config.trapBufferSizeLimit);
    // TODO: https://redmine.cursorinsight.com/issues/8579
    trap.bufferTimeout(this.config.trapBufferTimeout
      ? parseInt(this.config.trapBufferTimeout, 10)
      : undefined);
  }

  get config() {
    return this._config;
  }

  reset(resetLearning = false, emitEventCount = true) {
    this.stop();
    this.flushCollectedEvents();
    trap.stop();
    trap.generateNewStreamId();
    this._data = [];
    if (resetLearning) {
      this._learningData = [];
    }
    if (emitEventCount) {
      this.emitEventCount();
    }
  }

  // eslint-disable-next-line class-methods-use-this
  flushCollectedEvents() {
    return trap.flushCollectedEvents();
  }

  start() {
    this.reset();
    trap.start();

    const t = this;
    this._interval = setInterval(
      () => { t.emitEventCount(); },
      this.config.sentinelInterval,
    );
    this.emitEventCount();
  }

  stop() {
    trap.stop();
    if (this._interval !== null) {
      clearInterval(this._interval);
    }
    this._interval = null;
  }

  emitEventCount() {
    const eventCount = trap.collectedEventCount(
      (item) => item[0] === MOUSE_MOVE_EVENT_TYPE,
    );
    this.emit('eventsCollected', {
      eventCount,
      progress: (eventCount * 100.0) / this._config.requiredEventCount,
    });
  }

  // eslint-disable-next-line class-methods-use-this
  sendCustomData(customData) {
    trap.send(customData);
  }

  // eslint-disable-next-line class-methods-use-this
  get isRunning() {
    return (!!this._interval);
  }

  get progress() {
    const eventCount = trap.collectedEventCount(
      (item) => item[0] === MOUSE_MOVE_EVENT_TYPE,
    );
    return (eventCount * 100.0) / this._config.requiredEventCount;
  }

  finishLearning() {
    const evts = this.flushCollectedEvents();
    this._learningData = this._data.concat(evts);
    this.reset();
  }

  async submit(data) {
    const body = JSON.stringify({ data });

    // Set up content-type and its optional arguments: API key and envelope
    // encoding
    let contentType = 'text/plain';
    if (typeof this.config.apiKeyName === 'string'
      && this.config.apiKeyName !== ''
      && typeof this.config.apiKeyValue === 'string'
      && this.config.apiKeyValue !== '') {
      contentType += '; '
        + `${this.config.apiKeyName}=`
        + `${this.config.apiKeyValue}`;
    }
    contentType += '; encoding=json';

    const response = await fetch(this.config.sentinelUrl, {
      // *GET, POST, PUT, DELETE, etc.
      method: 'POST',

      // no-cors, *cors, same-origin
      mode: 'cors',

      // *default, no-cache, reload, force-cache, only-if-cached
      cache: 'no-cache',

      // include, *same-origin, omit
      credentials: 'omit',

      // necessary HTTP headers
      headers: {
        // No other headers are allowed in a preflight-less CORS request
        // For details: https://developer.mozilla.org/en-US/docs/Glossary/CORS-safelisted_request_header
        'Content-Type': contentType,
      },

      // manual, *follow, error
      redirect: 'follow',

      // no-referrer, *no-referrer-when-downgrade, origin,
      // origin-when-cross-origin, same-origin, strict-origin,
      // strict-origin-when-cross-origin, unsafe-url
      referrerPolicy: 'origin',

      // data to be sent
      body,
    });

    if (!response.ok) {
      const message = `An error occured: ${
        await this.getErrorMessage(response)}`;
      throw new Error(message);
    }
    // If OK, then parse and return the JSON result
    return response.json();
  }

  // eslint-disable-next-line class-methods-use-this
  async getErrorMessage(response) {
    try {
      const errorResponse = await response.json();
      if (errorResponse.result) {
        return errorResponse.result;
      }
    } catch {
      // Intentionally left empty, just return the status code as default error
      // message in this case
    }
    return response.status;
  }

  async validateTransaction() {
    try {
      this._data = this.flushCollectedEvents();
      const result = await this.submit(this._learningData.concat(this._data));
      return result.result === 'ok' && result.outcome === 'clean';
    } catch {
      return false;
    }
  }

  // eslint-disable-next-line class-methods-use-this
  get getInfoJSON() {
    const object = {
      date: new Date(),
      sessionId: trap.sessionId(),
      streamId: trap.streamId(),
    };

    return JSON.stringify(object);
  }
}

// Export a singleton only
const sentinel = new Sentinel();

export default sentinel;
