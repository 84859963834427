import {
  useAppStateDispatch,
  NEXT_STEP,
  useAppState,
  STAGE_LEARNING,
} from '../../contexts/AppStateProvider';

const Step1 = () => {
  const dispatch = useAppStateDispatch();
  const state = useAppState();
  const { stage } = state;

  const startDemo = () => {
    dispatch({ type: NEXT_STEP });
  };

  return (
    <>
      <div className={`flex-1
          ${stage === STAGE_LEARNING ? 'content-end' : 'content-center'}`}>
        <button
          className={`btn btn-primary
            ${stage === STAGE_LEARNING ? 'mb-8' : 'mb-0'}`}
          id={`start-${stage}`}
          onClick={startDemo}
        >
          Start {stage}
        </button>
      </div>
      {stage === STAGE_LEARNING && (
        <div className="flex-1 flex flex-row justify-end max-h-[50vh]">
          <div
            id="minimum-system-requirements"
            className="card w-3/4 bg-base-100 shadow-xl
              bg-white dark:bg-gray-900 text-black dark:text-white
              text-xs overflow-auto p-8"
          >
            <div className="font-bold mb-2">Minimum System Requirements</div>
            <ul className="list-disc space-y-2">
              <li>
                Used in a desktop environment: Microsoft Windows, Mac OS, or a
                desktop Linux (any distribution with a GUI) based PC or laptop
                computer. Don&apos;t use a tablet, mobile device, smart TV,
                gaming platform, in-car device, etc., as it might lead to
                unexpected results.
              </li>
              <li>
                Using a traditional pointing device: computer mouse, trackball,
                trackpoint, trackpad, touchpad, fundamentally any device that
                moves a mouse cursor on the screen in a continuous fashion.
                Notably, using a touchscreen won&apos;t work well with RDD
                because it doesn&apos;t provide enough data points.
              </li>
              <li>
                Using a single pointing device consistently throughout the demo
                session. E.g., switching from a regular mouse to a touchpad or
                vice versa during the session could easily trigger a false
                alert. It&apos;s ok if the &quot;attacker&quot; uses another
                type of pointing device, as long as it also falls into one of
                the above categories. In fact, this makes tampering detection
                easier. (Note: This limitation applies only to the demo version,
                and it won’t be an issue in practice if someone needs to switch
                later on.)
              </li>
              <li>
                Used with one of the more common web browsers: Mozilla Firefox,
                any Chromium based browser (notably Google Chrome, Microsoft
                Edge and Opera), or Safari. Niche browsers, e.g., that make
                extensive use of the keyboard for navigation, are not supported
                (e.g. Lynx, qute, or standard browsers with keyboard-supporting
                extensions like Vimium).
              </li>
              <li>
                The protocol must be followed, especially regarding the minimum
                amount of time spent with moving the pointing device. Lack of
                enough data results in loss of detection accuracy. Also make
                sure that the &quot;attacker&quot; actually takes control of the
                mouse pointer during the demo. Just telling the &quot;victim
                &quot; what to do is not enough to trigger the alert.
              </li>
            </ul>
          </div>
        </div>
      )}
    </>
  );
};

export default Step1;
