import { useEffect, useState } from 'react';
import Joyride, { ACTIONS, EVENTS, STATUS } from 'react-joyride';
import {
  STAGE_LEARNING,
  STAGE_VALIDATION,
  useAppState,
} from '../../contexts/AppStateProvider';

const STEPS = [{
  title: 'Welcome',
  content: <div>
    This is the demo page of the Graboxy Remote Desktop Detection.
  </div>,
  disableBeacon: false,
  placement: 'center',
  beaconPlacement: 'left-start',
  target: 'body',
}, {
  content: <div>During the demo, you will simulate a banking transfer. There
    will be two phases. During the first - learning - phase, you should perform
    the transfer on your machine locally.
  </div>,
  placement: 'auto',
  target: '#menu-learning',
  spotlightPadding: 5,
}, {
  content: <div>
    The next phase is the validation, when the remote party should perform
    a similar banking transfer on your machine by using a remote access tool.
  </div>,
  placement: 'auto',
  target: '#menu-validation',
  spotlightPadding: 5,
}, {
  title: 'Requirements',
  content: <div>
    Please note the minimum system requirements and ensure they are met for
    a successful demo.
  </div>,
  placement: 'auto',
  target: '#minimum-system-requirements',
  spotlightPadding: 20,
}, {
  title: 'Start learning',
  content: <div>
    Start the learning phase by clicking on the Start button.
  </div>,
  hideFooter: true,
  placement: 'left',
  beaconPlacement: 'left',
  spotlightClicks: true,
  target: '#start-learning',
  spotlightPadding: 0,
  styles: {
    beacon: {
      left: '-20px',
    },
  },
}, {
  title: 'Progress',
  content: <div>
    You can track the progress of mouse movement data collection. Please make
    sure to collect enough data before submitting the transaction.
  </div>,
  placement: 'top-end',
  target: '#gsr-progress',
  hideBackButton: true,
}, {
  title: 'Validation',
  content: <div>
    The learning phase has finished. Hand over your mouse to the remote party,
    and they should start the data collection by clicking on the Start button.
  </div>,
  target: '#start-validation',
  placement: 'left',
  beaconPlacement: 'left',
  hideFooter: true,
  spotlightClicks: true,
  spotlightPadding: 0,
  styles: {
    beacon: {
      left: '-20px',
    },
  },
}];

const STEP0_BEACON = {
  placement: 'left-start',
  target: '.mockup-browser',
};

const STEP0_TOOLTIP = {
  placement: 'center',
  target: 'body',
};

export default function JoyRide() {
  const [state, setState] = useState({
    continuous: true,
    run: false,
    steps: STEPS,
    stepIndex: 0,
  });

  const {
    continuous,
    run,
    stepIndex,
    steps,
  } = state;
  const { stage, step } = useAppState();

  const updateWithDelay = (newState) => {
    setState({
      ...state,
      run: false,
    });
    setTimeout(
      () => setState({
        ...state,
        run: true,
        ...newState,
      }),
      100,
    );
  };

  useEffect(() => {
    switch (stage) {
      case STAGE_LEARNING:
        // The first page sets the state running from the start
        if (step === 0) {
          setState({
            ...state,
            stepIndex: 0,
            run: true,
          });
        }
        // On the second page we show the 6th tooltip
        if (step === 1 && run === true) {
          updateWithDelay({
            stepIndex: 5,
          });
        }
        break;
      case STAGE_VALIDATION:
        // On the first page of verification we start with the 7th tooltip
        if (step === 0) {
          setState({
            ...state,
            run: true,
            stepIndex: 6,
          });
        }
        // On the second page we finish displaying the tooltips by setting the
        // index to a non existing one.
        if (step === 1 && run === true) {
          updateWithDelay({
            stepIndex: 7,
          });
        }
        break;
      default:
        break;
    }
  }, [stage, step]);

  const handleJoyrideCallback = (data) => {
    const {
      action,
      index,
      status,
      type,
    } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      // When the last tooltip was displayed we switch to beacon mode (by
      // setting continuous to false), so by default no tooltip will be shown if
      // the data collection is restarted.
      // We also set the the first step to support beacons.
      const newSteps = [
        {
          ...steps[0],
          ...STEP0_BEACON,
        },
        ...steps.splice(1),
      ];

      setState({
        ...state,
        steps: newSteps,
        continuous: false,
        run: false,
      });
    } else if (type === EVENTS.TOOLTIP) {
      if (continuous === false) {
        // If a beacon is clicked we switch to tooltip mode (by setting
        // continuous to true) and change the first step to display a tooltip in
        // the middle of the page.
        const newSteps = [
          {
            ...steps[0],
            ...STEP0_TOOLTIP,
          },
          ...steps.splice(1),
        ];

        updateWithDelay({
          steps: newSteps,
          continuous: true,
        });
      }
    } else if (type === EVENTS.STEP_AFTER) {
      let nextStepIndex = index;
      switch (action) {
        case ACTIONS.CLOSE: {
          // If a tooltip is closed we switch to beacon mode (by setting
          // continuous false) and change the first step to support beacons.
          const newSteps = [
            {
              ...steps[0],
              ...STEP0_BEACON,
            },
            ...steps.splice(1),
          ];

          updateWithDelay({
            steps: newSteps,
            continuous: false,
            run: true,
          });
          return;
        }
        case ACTIONS.NEXT:
          nextStepIndex += 1;
          break;
        case ACTIONS.PREV:
          nextStepIndex -= 1;
          break;
        default:
          break;
      }
      setState({
        ...state,
        stepIndex: nextStepIndex,
      });
    }
  };

  return (
    <Joyride
      callback={handleJoyrideCallback}
      continuous={continuous}
      run={run}
      disableScrolling={true}
      showProgress={false}
      showSkipButton={false}
      stepIndex={stepIndex}
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
          primaryColor: '#4900FF',
        },
        overlay: { height: '100%' },
      }}
    />
  );
}
