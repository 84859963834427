import { useEffect } from 'react';
import {
  FINISH_VALIDATION,
  useAppStateDispatch,
} from '../../contexts/AppStateProvider';
import sentinel from '../../components/GSR/sentinel';

const Step4 = () => {
  const dispatch = useAppStateDispatch();

  useEffect(() => {
    sentinel
      .validateTransaction()
      .then((successfulTransaction) => {
        dispatch({
          type: FINISH_VALIDATION,
          data: { successfulTransaction },
        });
      });
  }, []);

  return (
    <>
      <div className="card w-2/4 bg-base-100 shadow-xl mb-16
        bg-white dark:bg-gray-900 text-black dark:text-white">
        <div className="card-body flex justify-center">
          <h2 className="text-center">Security Check</h2>
          <div className="card-actions justify-center mt-5">
            <span className="loading loading-spinner loading-lg">
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4;
