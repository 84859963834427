import DarkModeToggler from '../DarkModeToggler';
import LogoLight from '../../images/graboxy-light.svg';
import LogoDark from '../../images/graboxy-dark.svg';
import SubMenu from './subMenu';
import {
  STAGE_LEARNING,
  STAGE_VALIDATION,
} from '../../contexts/AppStateProvider';

const Menu = () => (
  <div className="w-1/4 flex justify-start items-start flex-col">
    <div className="mb-5 pb-5">
      <div className="hidden dark:block">
        <img src={LogoDark} />
      </div>
      <div className="dark:hidden">
        <img src={LogoLight} />
      </div>
      <p>
        Remote Access Detection
        {' '}
        <span className="demo">Demo</span>
      </p>
    </div>
    <div className="mb-5 pb-5">
      <DarkModeToggler />
    </div>
    <div className='w-full mr-12'>
      <div>
        <SubMenu
          id="menu-learning"
          caption="Learning"
          stage={STAGE_LEARNING}
          numberOfSteps={3}
        />
      </div>
      <div className="mt-8">
        <SubMenu
          id="menu-validation"
          caption="Validation"
          stage={STAGE_VALIDATION}
          numberOfSteps={5}
        />
      </div>
    </div>
    <p className="text-slate-400 mt-auto text-center">
      © {new Date().getFullYear()}, Cursor Insight
    </p>
    <p className="text-slate-400">
      v0.8
    </p>
  </div>
);

export default Menu;
