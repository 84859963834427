import { useAppState } from '../../contexts/AppStateProvider';

const GSR = () => {
  // eslint-disable-next-line no-underscore-dangle
  const state = useAppState();
  const { progress } = state;

  return (
    <div id="gsr-progress">
      <label className="form-control mb-8">
        <div className="label">
          <span className="label-text">Data collection progress</span>
        </div>
        <progress
          id="progress-bar"
          className="progress progress-primary w-56"
          value={progress}
          max={100}
        />
      </label>
    </div>
  );
};

export default GSR;
