import PropTypes from 'prop-types';
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
} from 'react';
import sentinel from '../components/GSR/sentinel';

export const STAGE_LEARNING = 'learning';
export const STAGE_VALIDATION = 'validation';
export const NEXT_STEP = 'nextStep';
export const PREV_STEP = 'prevStep';
export const FINISH_LEARNING = 'finishLearning';
export const FINISH_VALIDATION = 'finishValidation';
export const RESTART = 'restart';
export const RESTART_VALIDATION = 'restartValidation';
export const UPDATE_PROGRESS = 'updateProgress';

const MAX_NUMBER_OF_STEPS = {};
MAX_NUMBER_OF_STEPS[STAGE_LEARNING] = 5;
MAX_NUMBER_OF_STEPS[STAGE_VALIDATION] = 5;

const initialState = {
  stage: STAGE_LEARNING,
  step: 0,
  progress: 0,
};

const AppStateDispatchContext = createContext(null);
const AppStateContext = createContext(initialState);

export function useAppState() {
  return useContext(AppStateContext);
}

export function useAppStateDispatch() {
  return useContext(AppStateDispatchContext);
}

function stateReducer(state, action) {
  const { type, data } = action;
  const { stage, step } = state;

  if (type !== UPDATE_PROGRESS) {
    sentinel.sendCustomData({
      action: 'stateChange',
      type,
      stage,
      step,
    });
  }

  switch (type) {
    case NEXT_STEP: {
      if (step === 0) {
        sentinel.start();
      }
      return {
        ...state,
        stage,
        step: step + 1,
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case PREV_STEP: {
      return {
        ...state,
        stage,
        step: step - 1,
      };
    }
    case FINISH_LEARNING: {
      sentinel.finishLearning();
      return {
        stage: STAGE_VALIDATION,
        step: 0,
      };
    }
    case FINISH_VALIDATION: {
      sentinel.stop();
      return {
        stage,
        step: 4,
        progress: 0,
        data: {
          ...state.data,
          ...data,
        },
      };
    }
    case RESTART: {
      sentinel.reset(true);
      return initialState;
    }
    case RESTART_VALIDATION: {
      sentinel.reset();
      return {
        stage: STAGE_VALIDATION,
        step: 0,
        progress: 0,
      };
    }
    case UPDATE_PROGRESS: {
      const { progress } = data;
      return {
        ...state,
        progress,
      };
    }
    default: {
      throw Error(`Unknown action: ${action.type}`);
    }
  }
}

export function AppStateProvider({ children }) {
  const [state, dispatch] = useReducer(
    stateReducer,
    initialState,
  );

  useEffect(() => {
    const onEventsCollected = ({ progress: p }) => {
      dispatch({ type: UPDATE_PROGRESS, data: { progress: p } });
    };
    sentinel.on('eventsCollected', onEventsCollected);
    return () => {
      sentinel.off('eventsCollected', onEventsCollected);
    };
  }, []);

  return (
    <AppStateContext.Provider value={state}>
      <AppStateDispatchContext.Provider value={dispatch}>
        {children}
      </AppStateDispatchContext.Provider>
    </AppStateContext.Provider>
  );
}

AppStateProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};
